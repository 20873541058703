import { DefaultTheme, createGlobalStyle } from 'styled-components';
import { ColorEntry, ColorScheme, ColorsObject } from '../types/layoutTypes';

type ColorsProps = {
    colors: ColorsObject;
};
const reduceColors = (colors: Record<string, ColorScheme>) => {
    return Object.entries(colors).reduce((finalAcc: any, [dimension, colorData]) => {
        const colorAcc = colorData.datasource_entries.reduce((acc: any, color: ColorEntry) => {
            if (color && color.name && (color.dimension_value || color.value)) {
                acc[
                    `--${color.name}${
                        dimension === 'original' || dimension === 'core'
                            ? ''
                            : dimension.charAt(0).toUpperCase() + dimension.slice(1)
                    }`
                ] = `#${color.dimension_value ?? color.value}`;
            }
            return acc;
        }, {});
        return { ...finalAcc, ...colorAcc };
    }, {});
};

const StyledGlobalStyle = createGlobalStyle<ColorsProps>`
  :root {
    ${props =>
        Object.entries(reduceColors(props.colors))
            .map(([name, value]) => `${name}: ${value}`)
            .join('; ')}
  }
`;

export const GlobalStyles: React.FC<ColorsProps> = ({ colors }) => {
    return <StyledGlobalStyle colors={colors} />;
};
export const theme: DefaultTheme = {
    colors: {},
};
