import { resolveRelations } from '@/shared/helpers/resolveRelations';
import { constructProps, fetchStory } from '@/shared/helpers/slugHelper';
import { ColorProvider } from '@/shared/providers/ColorContext';
import { GlobalStyles, theme } from '@/shared/providers/GlobalStyles';
import { ColorsObject } from '@/shared/types/layoutTypes';
import { ConfigStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { ISbStoriesParams, ISbStoryData, StoryblokComponent, useStoryblokState } from '@storyblok/react';
import { LogoJsonLd } from 'next-seo';
import { GetStaticProps } from 'next/types';
import { ThemeProvider } from 'styled-components';

type PageProps = {
    story: ISbStoryData<any>;
    config: ConfigStoryblok;
    colors: ColorsObject;
};

export default function Page({ story: initialStory, config, colors }: PageProps) {
    const story = useStoryblokState(initialStory) as ISbStoryData<any>;

    return (
        <>
            <LogoJsonLd logo={config?.content?.logo?.filename} url={process.env.NEXT_PUBLIC_BASE_URL || ''} />

            <ColorProvider>
                <ThemeProvider theme={theme}>
                    <GlobalStyles colors={colors} />
                    <StoryblokComponent blok={story.content} config={config} published_at={story.published_at} />
                </ThemeProvider>
            </ColorProvider>
        </>
    );
}
export const getStaticProps: GetStaticProps = async context => {
    const locale = context?.locale;
    const production = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
    const version: ISbStoriesParams = {
        version: production ? 'published' : 'draft',
        resolve_relations: resolveRelations,
        resolve_links: 'url',
        language: locale,
    };

    const { data, config, colors } = await fetchStory(undefined, version);

    return constructProps(data, config, colors);
};
